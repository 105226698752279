import { unauthClient, apiDefaults, axios } from "@/api/index.js";
import store from "@/state/store";
import { URL } from "@/api/global.env";

const Auth = {
  state: () => ({
    access: localStorage.getItem("access") || null,
    refresh: localStorage.getItem("refresh") || null,
    email: localStorage.getItem("email") || null,
    name: localStorage.getItem("name") || null,
    unauthClient: unauthClient,
    endpoints: {
      obtain: "/supplier/login",
      refresh: "/token/refresh/",
      register: "/accounts/register/",
    },
  }),
  mutations: {
    updateTokens(state, tokens) {
      localStorage.setItem("access", tokens.access);
      localStorage.setItem("refresh", tokens.refresh);
      localStorage.setItem("email", tokens.email);
      localStorage.setItem("name", tokens.name);
      console.log(tokens);
      state.access = tokens.access;
      state.refresh = tokens.refresh;
      console.log("state updated", state.access);
    },
    updateAccess(state, access) {
      localStorage.setItem("access", access);
      state.access = access;
    },
    removeToken(state) {
      localStorage.setItem("access", null);
      localStorage.setItem("refresh", null);
      localStorage.setItem("name", null);
      localStorage.setItem("email", null);
      state.access = null;
    },
  },
  getters: {
    client: (state) => {
      const apiClient = axios.create({
        ...apiDefaults,
        headers: {
          Authorization: `Bearer ${state.access}`,
        },
      });
      apiClient.interceptors.response.use(
        (response) => {
          // Return a successful response back to the calling service
          return response;
        },
        (error) => {
          // Return any error which is not due to authentication back to the calling service
          if (error.response.status !== 401) {
            return new Promise((resolve, reject) => {
              reject(error);
            });
          }

          console.log(error.config.url);
          // Logout user if token refresh didn't work or user is disabled
          if (
            error.config.url == `${URL}/api/token/refresh/` ||
            error.response.message == "Account is disabled."
          ) {
            // window.location.href = "/";

            return new Promise((reject) => {
              reject(error);
            });
          }

          // Try request again with new token
          return apiClient
            .post(`${URL}accounts/token/refresh/`, {
              refresh: localStorage.getItem("refresh"),
            })
            .then((response) => {
              // New request with new token
              const config = error.config;
              store.commit("updateAccess", {
                access: response.data.access,
              });
              config.headers[
                "Authorization"
              ] = `Bearer ${response.data.access}`;

              return new Promise((resolve, reject) => {
                apiClient
                  .request(config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            })
            .catch((error) => {
              Promise.reject(error);
            });
        }
      );
      return apiClient;
    },
  },
  actions: {
    obtainToken(context, authDetails) {
      return context.state.unauthClient
        .post(context.state.endpoints.obtain, authDetails)
        .then((response) => {
          this.commit("updateTokens", {
            access: response.data.access,
            refresh: response.data.refresh,
            email: response.data.email,
            name: response.data.name,
          });
        });
    },
    refreshToken(context) {
      const payload = {
        refresh: context.state.refresh,
      };
      context.state.unauthClient
        .post(context.state.endpoints.refresh, payload)
        .then((response) => {
          this.commit("updateAccess", response.data.access);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    registerUser(context, authDetails) {
      return context.state.unauthClient
        .post(context.state.endpoints.register, authDetails)
        .then((response) => {
          console.log(response, "response");
        });
    },
  },
};
export default Auth;
